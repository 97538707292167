import styled, { css } from "styled-components";

import { Button as AntButton } from "antd";
import {
  textAccentInfoBolder,
  colorlinkDefault,
  colorBorderFocused,
  backgroundBrandBold,
  backgroundBrandBoldHover,
  backgroundBrandBoldPressed,
  backgroundDangerBold,
  backgroundDangerBoldHover,
  backgroundDangerBoldPressed,
  colorTextDisabled,
  backgroundDisabled,
  colorBackgroundNeutral,
  colorBackgroundNeutralHovered,
  colorBackgroundNeutralPressed,
  backgroundSubtleDangerBold,
  backgroundSubtleDangerBoldHover,
  backgroundSubtleDangerBoldPressed,
  colorTextDefault,
} from "utils/constants/colors";

const Button = styled(AntButton)`
  cursor: pointer;
  outline: none;
  border: none;
  display: inline-block;
  padding: 0px 12px;
  border-radius: 2px;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;

  & > .anticon + span {
    margin-left: 10px;
  }

  & > .anticon {
    line-height: 0;
  }

  ${(props) =>
    props.primary &&
    css`
      color: white;
      background-color: ${props.danger
        ? backgroundDangerBold
        : backgroundBrandBold};

      &:focus,
      &:hover {
        color: white;
        background-color: ${props.danger
          ? backgroundDangerBoldHover
          : backgroundBrandBoldHover};
      }

      &:active {
        color: white;
        background-color: ${props.danger
          ? backgroundDangerBoldPressed
          : backgroundBrandBoldPressed};
      }

      &:disabled {
        background-color: ${backgroundDisabled};
        color: ${colorTextDisabled};
        pointer-events: none;
      }
    `}

  ${(props) =>
    props.secondary &&
    css`
      color: ${colorTextDefault};
      background-color:  ${
        props.danger ? backgroundSubtleDangerBold : colorBackgroundNeutral
      };
      }

      &:focus,
      &:hover {
        color: ${colorTextDefault};
        background-color: ${
          props.danger
            ? backgroundSubtleDangerBoldHover
            : colorBackgroundNeutralHovered
        };
      }

      &:active {
        color: ${colorTextDefault};
        background-color: ${
          props.danger
            ? backgroundSubtleDangerBoldPressed
            : colorBackgroundNeutralPressed
        };
      }

      &:disabled {
        background-color: ${backgroundDisabled};
        color: ${colorTextDisabled};
        pointer-events: none;
      }

   
    `}

  ${(props) =>
    props.size === "small" &&
    css`
      min-width: 72px;
      height: 24px;
      font-size: 14px;
      line-height: 20px;
    `}

  ${(props) =>
    props.size === "medium" &&
    css`
      height: 32px;
      min-width: 64px;
      font-size: 14px;
      line-height: 20px;
    `}

  ${(props) =>
    props.size === "large" &&
    css`
      height: 48px;
      min-width: 125px;
      font-size: 16px;
      line-height: 24px;
    `}

    ${(props) =>
    props.centered &&
    css`
      display: block;
      margin: 10px auto;
    `}

    &:focus-visible {
    outline: 3px solid ${colorBorderFocused};
    outline-offset: 3px;
  }
`;

Button.defaultProps = {
  primary: true,
  size: "medium",
  disabled: false,
  danger: false,
  centered: false,
};

export const TextButton = styled(Button)`
  min-width: 0;
  box-shadow: none;
  color: ${(props) =>
    props.danger ? backgroundDangerBoldHover : textAccentInfoBolder};
  padding: 0 10px;
  background: transparent;

  &:focus,
  &:hover {
    background: transparent;
    color: ${(props) =>
      props.danger ? backgroundDangerBold : colorlinkDefault};
  }

  &:active {
    background: transparent;
    color: ${(props) =>
      props.danger ? backgroundDangerBold : colorlinkDefault};
  }

  &:disabled {
    background: transparent;
    color: ${colorTextDisabled};
    pointer-events: none;
  }
`;

export default Button;
