import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import {
  faExclamationCircle,
  faCheckCircle,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { STATUS_COLOR, platinum, darkGray } from "utils/constants/colors";
import Modal from "components/ui/Modal";
import Button from "components/ui/Button";

const icons = {
  error: faExclamationCircle,
  warning: faExclamationCircle,
  success: faCheckCircle,
  info: faInfoCircle,
};

const ConfirmationModal = styled(Modal)`
  ${(props) =>
    props.type &&
    css`
      .ant-modal-body > .popup-container {
        padding: 24px;
        display: flex;
        background-color: white;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #64748b;
      }
    `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  flex-grow: 1;
`;

const Footer = styled.div`
  margin-top: 24px;

  ${Button}:nth-child(2) {
    margin-left: 16px;
    border: 1px solid ${platinum};
    color: ${darkGray};
  }
`;

const CancelButton = styled(Button)`
  margin-right: 16px;
`;

const ModalQuestion = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #3e4856;
  word-break: break-word;
`;

export function DEFAULT_CONFIRM_FOOTER(service, props) {
  return [
    <Button
      danger={props.danger}
      key="confirm-ok"
      data-qa="confirm-ok"
      onClick={() => service.resolve()}
      disabled={props.disabledConfirm}
      loading={props.loading}
    >
      {props.okLabel || "OK"}
    </Button>,
    <CancelButton
      secondary
      key="confirm-cancel"
      data-qa="confirm-cancel"
      onClick={() => service.reject()}
      disabled={props.loading}
    >
      {props.cancelLabel || "Cancel"}
    </CancelButton>,
  ];
}
const Question = ({ children }) => {
  return <ModalQuestion>{children}</ModalQuestion>;
};

ConfirmComponent.Question = Question;

function ConfirmComponent(props) {
  const { footer, service, visible, title, type, children } = props;
  const { t } = useTranslation();
  let header = title || t("Confirm action");

  if (!visible) {
    return null;
  }

  let footerComponents = footer || DEFAULT_CONFIRM_FOOTER;
  if (typeof footerComponents === "function") {
    footerComponents = footerComponents(service, props);
  }

  if (type) {
    return (
      <ConfirmationModal {...props} title={null} closable={!type} footer={null}>
        <FontAwesomeIcon
          size="3x"
          key="icon"
          icon={icons[props.type]}
          color={STATUS_COLOR[props.type]}
        />
        <Content>
          {children}
          <Footer>{footerComponents}</Footer>
        </Content>
      </ConfirmationModal>
    );
  }

  return (
    <ConfirmationModal
      {...props}
      title={header}
      closable={!type}
      footer={footerComponents}
    />
  );
}

export default connect(
  (_, ownProps) => ({
    visible: ownProps.service.isOpened(),
  }),
  (_, ownProps) => ({
    onCancel: ownProps.service.close,
  })
)(ConfirmComponent);
