import React from "react";
import * as YAML from "yaml";
import isEqual from "fast-deep-equal";
import base64 from "base-64";
import omit from "lodash/omit";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { createSelector } from "reselect";
import i18next from "i18next";

import Button from "components/ui/Button";
import { createConnector } from "modules/binder";
import api from "services/api";
import store from "services/store";
import notifications from "services/notifications";
import { appProfileDetailFetcher } from "state/appprofiles/services/details";
import {
  helmPackVersionFetcher,
  ociRepositoriesFetcher,
} from "modules/profileIDE/state/packs";
import { initializeAppDetailPage } from "state/appprofiles/actions/details";
import { error } from "utils/constants/colors";
import Icon from "components/ui/Icon";
import { faHexagonExclamation } from "@fortawesome/pro-solid-svg-icons";

const ActionsBar = styled.div`
  display: flex;
  padding: 12px 32px;
  align-items: center;
  background-color: #f2fff9;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;

  > button {
    margin-right: 12px;
  }
`;

const FooterError = styled.span`
  color: ${error};
`;

const ErrorIcon = styled(Icon)`
  margin-right: 4px;
`;

// manifests children are set when figuring out the operations
const TYPE_PAYLOAD = {
  helm(draft) {
    const packs = helmPackVersionFetcher
      .key(draft.packName)
      .selector(store.getState())?.result;

    // for some reason entity pack has the uid in metadata name
    const selectedPack = (packs?.tags || []).find(
      (pack) =>
        pack.packUid ===
        (draft?.packUid ||
          draft?.pack?.metadata?.uid ||
          draft?.pack?.metadata?.name)
    );

    return {
      name: draft.name,
      type: draft.type,
      version: selectedPack?.version || draft?.version,
      registryUid: draft.registryUid,
      values: draft.values,
      sourceAppTierUid: draft.packUid,
      uid: draft.uid,
      ...(!selectedPack && {
        properties: [
          {
            name: "helmChartName",
            value: draft.name,
          },
        ],
      }),
    };
  },
  manifest(draft) {
    return {
      name: draft.name,
      type: draft.type,
      uid: draft.uid,
      installOrder: parseInt(draft.installOrder) || 0,
      values: draft.values,
    };
  },
  "operator-instance": (draft) => {
    return {
      name: draft.name,
      type: "operator-instance",
      properties: (draft?.parameters?.inputParameters || []).map(
        (parameter) => {
          const parameterValue = draft?.[parameter.name];
          let value =
            parameterValue?.toString() || parameter?.value?.toString();

          if (parameterValue && parameter.format === "base64") {
            value = base64.encode(parameterValue);
          }

          return {
            name: parameter.name,
            value: value,
          };
        }
      ),
      values: draft.values,
      sourceAppTierUid: draft.packUid,
      version: draft.version,
      uid: draft.uid,
    };
  },
  container: (draft) => {
    const draftCopy = { ...draft };
    draftCopy.registryUrl =
      (ociRepositoriesFetcher.selector(store.getState())?.result || []).find(
        (repo) => {
          return repo.metadata.uid === draft.containerRegistryUid;
        }
      )?.spec?.endpoint || "";

    draftCopy.env = [...(draftCopy?.env || [])]
      .map((env) => {
        if (!env.key || !env.value) {
          return null;
        }
        return {
          name: env.key,
          value: env.value,
        };
      })
      .filter(Boolean);
    draftCopy.serviceType =
      draftCopy.access === "public" ? "LoadBalancer" : "ClusterIP";

    draftCopy.command = draftCopy?.command?.split(" ")?.filter(Boolean);
    draftCopy.args = [...(draftCopy?.args || [])].filter(Boolean);

    const initialValues = YAML.parse(draft.values);

    const valuesPayload = {
      registryUrl: draftCopy.registryUrl,
      image: draftCopy?.image,
      replicas: draftCopy?.replicas,
      ingressHost: "{{.spectro.system.appdeployment.ingress.host}}",
      access: draftCopy.access,
      ports: draftCopy?.ports,
      serviceName: initialValues?.containerService?.serviceName || draft.name,
      serviceType: draftCopy.serviceType,
      args: draftCopy?.args?.length === 0 ? undefined : draftCopy?.args,
      command:
        draftCopy?.command?.length === 0 ? undefined : draftCopy?.command,
      env: draftCopy.env.length === 0 ? undefined : draftCopy.env,
      volumeName: !!draftCopy?.volumeName ? draftCopy?.volumeName : undefined,
      volumeSize: !!draftCopy?.volumeSize ? draftCopy?.volumeSize : undefined,
      pathToMount: !!draftCopy?.pathToMount
        ? draftCopy?.pathToMount
        : undefined,
    };

    const reducedValuesPayload = Object.entries(valuesPayload).reduce(
      (acc, [key, value]) => {
        if (!!value) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    return {
      uid: draft.uid,
      name: draft.name,
      type: "container",
      version: draft.version,
      values: YAML.stringify({
        ...initialValues,
        containerService: {
          ...(initialValues?.containerService || {}),
          ...reducedValuesPayload,
        },
      }),
      registryUid: draft.registryUid,
      containerRegistryUid: draft.containerRegistryUid,
      sourceAppTierUid: draft.packUid,
    };
  },
};

export function getAppTiersOperations({ fileList, drafts }) {
  const files = fileList.files;
  const deletedFiles = fileList.deletedFiles;
  const entities = fileList.entities;
  const childrenMap = fileList.childrenMap;
  const deletedChildren = fileList.deletedChildren;

  return [...files, ...deletedFiles]
    .map((file) => {
      const draft = drafts[file];
      const entity = entities[file];
      const children = childrenMap[file];
      const deletedChildrenGuids = deletedChildren
        .filter((pair) => pair.parentGuid === file)
        .map(({ guid }) => guid);

      // manifest operations
      const manifestsOps = [...(children || []), ...deletedChildrenGuids].map(
        (childGuid) => {
          let operation = "ignore";

          // if no manifest uid but I have parent Uid
          if (
            !drafts[childGuid].uid &&
            draft.uid &&
            !deletedChildrenGuids.includes(childGuid)
          ) {
            operation = "create";
          }

          if (
            drafts[childGuid].uid &&
            entities[childGuid].values !== drafts[childGuid].values
          ) {
            operation = "update";
          }

          if (
            drafts[childGuid].uid &&
            deletedChildren.find(
              (deletedChild) =>
                deletedChild.guid === childGuid &&
                deletedChild.parentGuid === file
            )
          ) {
            operation = "delete";
          }

          return { ...drafts[childGuid], operation };
        }
      );

      if (!entity.persisted && deletedFiles.includes(file)) {
        return null;
      }

      const draftPayload = omit(TYPE_PAYLOAD?.[draft.type]?.(draft) || {}, [
        "manifests",
      ]);

      let operation = "create";

      if (entity.persisted) {
        const entityPayload = omit(TYPE_PAYLOAD?.[entity.type]?.(entity), [
          "manifests",
        ]);

        operation = isEqual(entityPayload, draftPayload) ? "ignore" : "update";

        if (entityPayload.sourceAppTierUid !== draftPayload?.sourceAppTierUid) {
          operation = "replace";
        }

        if (deletedFiles.includes(file)) {
          operation = "delete";
        }
      }

      if (children?.length > 0) {
        draftPayload.manifests = children.map((childGuid) => ({
          name: drafts[childGuid].name,
          content: drafts[childGuid].values,
        }));
      }

      return {
        ...draftPayload,
        operation,
        manifestsOps,
        guid: entity?.guid,
      };
    })
    .filter(Boolean);
}

export default createConnector({
  selectors: {
    displayActionsBar: createSelector(
      (state) => state.fileList || {},
      (state) => state.drafts || {},
      (fileList, drafts) => {
        if (fileList.files.length === 0) {
          return false;
        }

        const appTiers = getAppTiersOperations({ fileList, drafts });

        return appTiers.some((tier) => {
          if (!tier) {
            return false;
          }

          const hasManifestUpdates =
            tier?.manifestsOps?.length > 0 &&
            (tier?.manifestsOps || []).some(
              (manifest) => manifest.operation !== "ignore"
            );

          if (tier.operation !== "ignore" || hasManifestUpdates) {
            return true;
          }
          return false;
        });
      }
    ),
    disabledSave: createSelector(
      (state) => state.validations,
      (validations) =>
        Object.keys(validations).find(
          (fileGuid) => validations[fileGuid]?.length > 0
        )
    ),
    isLoading: createSelector(
      (state) => state.validations.isLoading,
      (isLoading) => {
        return isLoading;
      }
    ),
  },
  actions: {
    onSave: () => async (_, module) => {
      module.dispatch({
        type: module.actions.validations.isLoading,
        isLoading: true,
      });

      const fileList = module.state.fileList;
      const drafts = module.state.drafts;
      const appTiers = getAppTiersOperations({ fileList, drafts });
      const profile = appProfileDetailFetcher.selector(store.getState()).result;

      const errors = await store.dispatch(module.form.actions.validateForm());

      if (errors.length > 0) {
        module.dispatch({
          type: module.actions.validations.isLoading,
          isLoading: false,
        });
        return;
      }

      const promises = appTiers
        .flatMap((tier) => {
          if (!tier) {
            return null;
          }

          const manifestsPromises = (tier?.manifestsOps || [])
            .map((manifest) => {
              if (manifest.operation === "create") {
                return api.post(
                  `v1/appProfiles/${profile.metadata.uid}/tiers/${tier.uid}/manifests`,
                  {
                    name: manifest.name,
                    content: manifest.values,
                  }
                );
              }

              if (manifest.operation === "update") {
                return api.put(
                  `v1/appProfiles/${profile.metadata.uid}/tiers/${tier.uid}/manifests/${manifest.uid}`,
                  {
                    name: manifest.name,
                    content: manifest.values,
                  }
                );
              }

              if (manifest.operation === "delete") {
                return api.delete(
                  `v1/appProfiles/${profile.metadata.uid}/tiers/${tier.uid}/manifests/${manifest.uid}`
                );
              }
              return null;
            })
            .filter(Boolean);

          if (tier.operation === "delete") {
            return api.delete(
              `v1/appProfiles/${profile.metadata.uid}/tiers/${tier.uid}`
            );
          }

          if (tier.operation === "update") {
            const payload = omit(tier, ["manifests", "manifestsOps"]);

            const updateTierPromise = api.put(
              `v1/appProfiles/${profile.metadata.uid}/tiers/${tier.uid}`,
              payload
            );

            if (
              (tier?.manifestsOps || []).every(
                (manifest) => manifest.operation === "ignore"
              )
            ) {
              return updateTierPromise;
            }

            return [updateTierPromise, manifestsPromises];
          }

          if (tier.operation === "replace") {
            const payload = omit(tier, ["manifests", "manifestsOps"]);

            return api.patch(`v1/appProfiles/${profile.metadata.uid}/tiers`, {
              replaceWithAppTier: tier.uid,
              appTier: payload,
            });
          }

          if (tier.operation === "create") {
            return api.post(
              `v1/appProfiles/${profile.metadata.uid}/tiers`,
              tier
            );
          }

          if (manifestsPromises?.length > 0) {
            return manifestsPromises;
          }

          return null;
        })
        .filter(Boolean);

      // need a better way to treat errors
      if (promises.length > 0) {
        try {
          const results = await Promise.allSettled(promises);
          const errors = results
            .filter((result) => result.status === "rejected")
            .map((result) => result.reason.message);

          module.dispatch({
            type: module.actions.validations.isLoading,
            isLoading: false,
          });
          if (errors.length) {
            notifications.error({
              message: i18next.t("App profile partially saved"),
              description: errors.join("<br />"),
            });
            return;
          }
        } catch (err) {
          module.dispatch({
            type: module.actions.validations.isLoading,
            isLoading: false,
          });
          notifications.error({
            message: i18next.t("Something went wrong"),
            description: err?.message,
          });
          return Promise.reject();
        }

        store.dispatch(initializeAppDetailPage({ uid: profile.metadata.uid }));

        notifications.success({
          message: i18next.t(
            "App profile '{{name}}' has been updated successfully",
            { name: profile.metadata?.name }
          ),
        });
      }
    },
  },
}).connect(({ onSave, displayActionsBar, disabledSave, isLoading }) => {
  const { t } = useTranslation();

  if (!displayActionsBar) {
    return null;
  }

  return (
    <ActionsBar>
      <Button
        data-qa="save-app-profile"
        onClick={onSave}
        disabled={disabledSave}
        loading={isLoading}
      >
        {t("Save Changes")}
      </Button>
      {/* <TextButton
        data-qa="discard-app-profile"
        onClick={onDiscard}
      >
        {t("Discard Changes")}
      </TextButton> */}
      {disabledSave && (
        <FooterError>
          <ErrorIcon awesome={faHexagonExclamation} />
          {t(
            "Resolve the tier validation errors in order to save the changes."
          )}
        </FooterError>
      )}
    </ActionsBar>
  );
});
