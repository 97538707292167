import React from "react";
import vertexEnvironmentPath from "assets/images/login/vertex/environment.png";
import customEnvironmentPath from "assets/images/login/custom/environment.png";
import paletteEnvironmentPath from "assets/images/login/environment.png";
import VertexColoredLogoWhiteText from "assets/icons/vertex/palette_logo_white_text.svg?react";
import SmallVertexLogo from "assets/icons/vertex/small_logo.svg?react";
import VertexColoredLogoColoredText from "assets/icons/vertex/palette_logo_colored_text.svg?react";
import VertexFullWhiteLogo from "assets/icons/vertex/palette_logo_full_white.svg?react";
import PaletteColoredLogoWhiteText from "assets/icons/logo_landscape_white.svg?react";
import PaletteColoredLogoColoredText from "assets/icons/logo_landscape_colored.svg?react";
import FullWhiteLogo from "assets/icons/logo_landscape_white.svg?react";
import SmallPaletteLogo from "assets/icons/brands/palette.svg?react";
import startPageIllustration from "assets/images/start-page-illustration.png";
import vertexStartPageIllustration from "assets/images/start-page-illustration-vertex.png";

import SmallLightCDWLogo from "assets/icons/cdw/small_light_logo.svg?react";
import CDWLightLogo from "assets/icons/cdw/light_logo.svg?react";
import CDWColoredLogo from "assets/icons/cdw/colored_logo.svg?react";

import appEnv from "services/app";
import Color from "color";
import {
  backgroundBrandBold,
  inputBorder,
  sidebarBackground,
} from "utils/constants/colors";

const DEFAULT_THEME = {
  showSidebarBottomLogo: true,
  envBackgroundPath: paletteEnvironmentPath,
  productTourBackgroundPath: customEnvironmentPath,
  buttonBackground: backgroundBrandBold,
  LightLogo: FullWhiteLogo,
  ColoredLogo: PaletteColoredLogoColoredText,
  ColoredLogoWhiteText: PaletteColoredLogoWhiteText,
  SmallColoredLogo: SmallPaletteLogo,
  SideMenuLogo: PaletteColoredLogoWhiteText,
  customMenuLogoStyle: true,
  inputBorder: inputBorder,
  secondaryButtonColor: "#332e76",
  linkColor: "#1F7A78",
  linkColorHover: "#135757",
  textColor: "#161514",
  favicon: "/favicon/favicon-32x32.png",
  startPageIllustration,
};

const VERTEX_DEFAULT_THEME = {
  isVertex: true,
  showSidebarBottomLogo: true,
  envBackgroundPath: vertexEnvironmentPath,
  productTourBackgroundPath: customEnvironmentPath,
  buttonBackground: backgroundBrandBold,
  LightLogo: VertexFullWhiteLogo,
  ColoredLogo: VertexColoredLogoColoredText,
  ColoredLogoWhiteText: VertexColoredLogoWhiteText,
  SmallColoredLogo: SmallVertexLogo,
  SideMenuLogo: VertexColoredLogoWhiteText,
  customMenuLogoStyle: false,
  inputBorder: inputBorder,
  secondaryButtonColor: "#206CD1",
  linkColor: "#1F7A78",
  linkColorHover: "#135757",
  textColor: "#161514",
  favicon: "/favicon/vertex/favicon-32x32.png",
  startPageIllustration: vertexStartPageIllustration,
};

function validateColorsOrFallback(colors = {}, fallbacks = {}) {
  return Object.keys(fallbacks).reduce((acc, key) => {
    let color = fallbacks[key];
    try {
      color = Color(colors[key])?.hex();
    } catch (e) {
      console.error(e);
    }
    acc[key] = color;
    return acc;
  }, {});
}

const systemThemeVersions = {
  v1({ themeData, isMenuExpanded, defaultThemeColors, defaults }) {
    const colors = validateColorsOrFallback(
      themeData.colors,
      defaultThemeColors
    );
    const inverseDarkColor = "#111111";
    const inverseLightColor = "#ffffff";
    const hasSidebarLogos =
      themeData.branding.sidebar && themeData.branding["minimized-sidebar"];

    const invertedColors = {
      dark: inverseDarkColor,
      light: inverseLightColor,
      login: Color(colors.login).isDark()
        ? inverseLightColor
        : inverseDarkColor,
      sidebar: Color(colors.sidebar).isDark()
        ? inverseLightColor
        : inverseDarkColor,
    };

    const asDark = {
      login: Color(colors.login).isDark() ? colors.login : inverseDarkColor,
      sidebar: Color(colors.sidebar).isDark()
        ? colors.sidebar
        : inverseDarkColor,
    };

    const asLight = {
      login: Color(colors.login).isLight() ? colors.login : inverseLightColor,
      sidebar: Color(colors.sidebar).isLight()
        ? colors.sidebar
        : inverseLightColor,
    };

    return {
      showSidebarBottomLogo: true,
      envBackgroundPath: defaults.envBackgroundPath,
      productTourBackgroundPath: customEnvironmentPath,
      buttonBackground: colors.login,
      sidebarBackground: colors.sidebar,
      LightLogo: themeData.branding.sidebar
        ? () => <img alt="product logo" src={themeData.branding.sidebar} />
        : defaults.LightLogo,
      ColoredLogo: themeData.branding.login
        ? () => <img alt="product logo" src={themeData.branding.login} />
        : defaults.ColoredLogo,
      ColoredLogoWhiteText: themeData.branding.sidebar
        ? () => <img alt="product logo" src={themeData.branding.sidebar} />
        : defaults.ColoredLogoWhiteText,
      SmallColoredLogo: themeData.branding["minimized-sidebar"]
        ? () => (
            <img
              alt="tenant logo"
              src={themeData.branding["minimized-sidebar"]}
            />
          )
        : defaults.SmallColoredLogo,
      SideMenuLogo: themeData.branding.sidebar
        ? () => (
            <img
              alt="sidebar product logo"
              src={
                isMenuExpanded
                  ? themeData.branding.sidebar
                  : themeData.branding["minimized-sidebar"] ||
                    themeData.branding.sidebar
              }
            />
          )
        : defaults.SideMenuLogo,
      inputBorder: asDark.login,
      secondaryButtonColor: asDark.login,
      linkColor: asDark.login,
      linkColorHover: asDark.login,
      textColor: asDark.login,
      favicon: themeData.branding.favicon,
      colors: {
        ...colors,
        inverted: invertedColors,
        dark: asDark,
        light: asLight,
      },
      resizeOnCollapse: !!themeData.branding.sidebar,
      customMenuLogoStyle: !hasSidebarLogos,
      startPageIllustration: defaults.startPageIllustration,
    };
  },
};

export function useTheme({ isMenuExpanded = false, isAdminMode = false } = {}) {
  const themeData = appEnv.theme;
  if (themeData?.variant === "system") {
    return themeData
      ? systemThemeVersions.v1({
          themeData,
          isMenuExpanded,
          defaultThemeColors: {
            login: backgroundBrandBold,
            sidebar: sidebarBackground,
          },
          defaults: themeData.isVertex ? VERTEX_DEFAULT_THEME : DEFAULT_THEME,
        })
      : {};
  }

  if (themeData?.variant === "CDW") {
    return {
      showSidebarBottomLogo: false,
      envBackgroundPath: paletteEnvironmentPath,
      productTourBackgroundPath: customEnvironmentPath,
      authBackgroundGradient:
        "radial-gradient(157.74% 150.08% at -8.62% -17.47%, #b54aa1 0%, #653a89 50%, #090f2d 100%)",
      buttonBackground: "#150404",
      LightLogo: CDWLightLogo,
      ColoredLogo: CDWColoredLogo,
      ColoredLogoWhiteText: CDWLightLogo,
      SmallColoredLogo: CDWColoredLogo,
      SideMenuLogo: isMenuExpanded ? CDWLightLogo : SmallLightCDWLogo,
      customMenuLogoStyle: false,
      inputBorder: "#BCC3CD",
      secondaryButtonColor: "#150404",
      linkColor: "#206CD1",
      linkColorHover: "#206CD1",
      textColor: "#206cd1",
      startPageIllustration,
    };
  }

  if (themeData?.variant === "VERTEX") {
    return {
      variant: "VERTEX",
      ...VERTEX_DEFAULT_THEME,
    };
  }

  if (themeData?.variant === "custom") {
    if (themeData?.version === "v1") {
      return {
        variant: "custom",
        showSidebarBottomLogo: true,
        envBackgroundPath: customEnvironmentPath,
        buttonBackground: themeData.colors.login,
        productTourBackgroundPath: customEnvironmentPath,
        authBackgroundGradient:
          "linear-gradient(178.87deg, #141621 -14.31%, #353363 46.58%, #5e58b6 123.45%)",
        sidebarBackground: themeData.colors.sidebar,
        LightLogo: () => (
          <img alt="product logo" src={themeData.branding.sidebar} />
        ),
        ColoredLogo: () => (
          <img alt="product logo" src={themeData.branding.login} />
        ),
        ColoredLogoWhiteText: () => (
          <img alt="product logo" src={themeData.branding.sidebar} />
        ),
        SmallColoredLogo: () => (
          <img
            alt="tenant logo"
            src={themeData.branding.mark || themeData.branding.login}
          />
        ),
        SideMenuLogo: () => (
          <img alt="sidebar product logo" src={themeData.branding.sidebar} />
        ),
        inputBorder: themeData.colors.login,
        secondaryButtonColor: themeData.colors.login,
        linkColor: themeData.colors.login,
        linkColorHover: themeData.colors.login,
        textColor: themeData.colors.login,
        resizeOnCollapse: true,
        startPageIllustration,
      };
    }
  }

  return {
    ...DEFAULT_THEME,
    SideMenuLogo: isAdminMode ? PaletteColoredLogoWhiteText : FullWhiteLogo,
  };
}
