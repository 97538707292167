import React from "react";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";
import isSemver from "semver/functions/valid";
import * as YAML from "yaml";

import language from "i18next";
import isUrl from "validator/lib/isURL";
import isIP from "validator/lib/isIP";
import isJSON from "validator/lib/isJSON";
import isIPRange from "validator/lib/isIPRange";
import isFQDN from "validator/lib/isFQDN";
import isEmail from "validator/lib/isEmail";
import isPort from "validator/lib/isPort";
import debouncePromise from "utils/debouncePromise";
import { isValidRegex } from "utils/strings";
import { MASKED_VARIABLE_DEFAULT_VALUE } from "utils/constants";

const KUBERNETES_TAGS_REGEX = /^[a-zA-Z0-9]([-a-zA-Z0-9._]*[a-zA-Z0-9])?$/;
const TAG_VALUE_REGEX = /^([A-Za-z0-9][-A-Za-z0-9_.]*[A-Za-z0-9])$/;
const TAG_KEY_REGEX =
  /^([A-Za-z0-9][-A-Za-z0-9_.]*(\/[-A-Za-z0-9_.]+)?[A-Za-z0-9])$/;
const NAME_REGEX = /^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/;
export const KUBERNETES_NAME_REGEX =
  /^[a-zA-Z0-9]([-a-zA-Z0-9_.]*[a-zA-Z0-9])?$/;

function isPortRange(portRange) {
  if (portRange.includes("-")) {
    const [start, end] = portRange.split("-");
    return isPort(start) && isPort(end);
  }

  return false;
}

export const KUBERNETES_NAME_RULES = [
  () => language.t("Must be 63 characters or less"),
  () => language.t("Must start and end with an alphanumeric character"),
  () =>
    language.t(
      "Can contain only alphanumeric characters, dots, dashes or underscores"
    ),
];

const KUBERNETES_TAG_RULES = [
  () =>
    language.t(
      "Tags follow the key-value pair format (eg: kubernetes:enabled)."
    ),
  () =>
    language.t(
      "Alternatively you can provide only the key. Omitting the value will translate to provided-key:spectro__tag."
    ),
  () => language.t("Value and key must be 63 characters or less"),
  () =>
    language.t(
      "Value and key must start and end with an alphanumeric character"
    ),
  () =>
    language.t(
      "Value and key can contain only alphanumeric characters, dots, dashes or underscores"
    ),
];

const TagsDescription = styled.ul`
  padding: 10px 25px;
`;

export const KubernetesTagRulesDescription = () => {
  return (
    <TagsDescription>
      {KUBERNETES_TAG_RULES.map((rule, index) => (
        <li key={index}>{rule()}</li>
      ))}
    </TagsDescription>
  );
};

const NAME_RULES = [
  () =>
    language.t(
      "Name must consist of a lower case alphanumeric characters or '-'"
    ),
  () =>
    language.t(
      "Name must start and end with an alphanumeric character (e.g 'my-name' or '123-abc')"
    ),
  () => language.t("Name must be 32 characters or less"),
];

const GuidelinesTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    width: 400px;
  }
`;

export function Missing({ message = () => language.t("Missing field") } = {}) {
  return (value) => {
    if (Array.isArray(value)) {
      // fails for arrays where the 1st value can be 0...but it's kind of a very small change to occur
      return value.length === 0 || value.every((val) => !!!val)
        ? message()
        : false;
    }
    return ["", undefined, null, false].includes(value) ? message() : false;
  };
}

export function isZeroOrGreater({
  message = () => language.t("Value must be 0 or greater than 0"),
} = {}) {
  return (value) => (value >= 0 ? false : message());
}

export function ApplyIf(conditionFn, continueFn) {
  return function validationFn(...args) {
    const isConditionMet = conditionFn(...args);
    if (!isConditionMet) {
      return false;
    }

    return continueFn(...args);
  };
}

const InvalidFieldMessage = ({
  text,
  tooltipPlacement = "right",
  rules = KUBERNETES_TAG_RULES,
}) => {
  const { t } = useTranslation();

  return (
    <GuidelinesTooltip
      defaultVisible={true}
      overlayStyle={{ width: 400 }}
      getPopupContainer={(triggerNode) => triggerNode}
      placement={tooltipPlacement}
      title={
        <TagsDescription>
          {rules.map((ruleMessage, index) => (
            <li key={index}>{ruleMessage()}</li>
          ))}
        </TagsDescription>
      }
    >
      {text ||
        t(
          "One or more tags are invalid. Tags must respect kubernetes guidelines"
        )}{" "}
      <FontAwesomeIcon icon={faInfoCircle} />
    </GuidelinesTooltip>
  );
};

export function areValidDomainTags({
  genericMessage = () => language.t("One or more domains are invalid"),
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    const invalidTags = value.filter((domain) => !isFQDN(domain));

    return invalidTags.length === 0
      ? false
      : {
          result: genericMessage(),
          invalidTags,
        };
  };
}

export function areValidIPTags({
  genericMessage = () => language.t("One or more ips are invalid"),
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    const invalidTags = value.filter((ip) => !isIP(ip));

    return invalidTags.length === 0
      ? false
      : {
          result: genericMessage(),
          invalidTags,
        };
  };
}

export function areValidEmailTags({
  genericMessage = () => language.t("One or more emails are invalid"),
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    const invalidTags = value.filter((email) => !isEmail(email));

    return invalidTags.length === 0
      ? false
      : {
          result: genericMessage(),
          invalidTags,
        };
  };
}

function splitTag(tag) {
  if (tag.includes(":")) {
    const [key, value = ""] = tag.split(":");
    return {
      key: key.trim(),
      value: value.trim(),
    };
  }
  return {
    key: tag.trim(),
    value: "",
  };
}

function areTagKeysDuplicate(value) {
  const keys = value.reduce((acc, item) => {
    const { key } = splitTag(item);
    acc.push(key);
    return acc;
  }, []);
  const set = new Set(keys);
  return keys.length !== set.size;
}

export function areValidKubernetesTags({
  errorMessageText,
  tooltipPlacement,
  genericMessage = ({ text, tooltipPlacement, rules } = {}) => (
    <InvalidFieldMessage
      text={text}
      tooltipPlacement={tooltipPlacement}
      rules={rules}
    />
  ),
  rules,
  tagValuesRegex = KUBERNETES_TAGS_REGEX,
  tagKeysRegex = KUBERNETES_TAGS_REGEX,
  onlySpectroTags = false,
} = {}) {
  return (tagValue) => {
    if (!tagValue) {
      return false;
    }
    if (onlySpectroTags) {
      const invalidTagValues = tagValue.filter((tag) => {
        return (
          !tag.includes(":") && (!tag.match(tagValuesRegex) || tag.length > 63)
        );
      });
      if (invalidTagValues.length === 0 && areTagKeysDuplicate(tagValue)) {
        return {
          result: genericMessage({
            text: language.t("Tags with duplicate keys are not allowed"),
            tooltipPlacement,
            rules,
          }),
          invalidTagValues,
        };
      }

      return invalidTagValues.length === 0
        ? false
        : {
            result: genericMessage({
              text: errorMessageText,
              tooltipPlacement,
              rules,
            }),
            invalidTagValues,
          };
    }

    const keys = tagValue
      .map((tag) => {
        if (tag.includes(":")) {
          return tag.split(":")?.[0];
        }
        return tag;
      })
      .filter(Boolean);

    const values = tagValue
      .map((tag) => {
        if (tag.includes(":")) {
          return (tag.split(":")?.[1] || "").trim();
        }
        return false;
      })
      .filter(Boolean);

    const invalidTagValues = [
      ...new Set(
        values
          .map((tag) => {
            if (!tag.match(tagValuesRegex) || tag.length > 63) {
              return tagValue.find((originalTag) => {
                if (originalTag.includes(":")) {
                  const originalValue = originalTag.split(":")?.[1];
                  return originalValue.includes(tag);
                }
                return false;
              });
            }
            return undefined;
          })
          .filter(Boolean)
      ),
    ];

    const invalidTagKeys = [
      ...new Set(
        keys
          .map((key) => {
            if (!key.match(tagKeysRegex) || key.length > 63) {
              return tagValue.find((originalTag) => {
                const originalKey = originalTag.split(":")?.[0];
                return originalKey.trim() === key.trim();
              });
            }
            return undefined;
          })
          .filter(Boolean)
      ),
    ];

    const invalidTags = [...invalidTagValues, ...invalidTagKeys];

    return invalidTags.length === 0
      ? false
      : {
          result: genericMessage({
            text: errorMessageText,
            tooltipPlacement,
            rules,
          }),
          invalidTags,
        };
  };
}

export function areValidKeyValueK8sLabels() {
  return areValidKubernetesTags({
    genericMessage: () => <InvalidFieldMessage rules={KUBERNETES_TAG_RULES} />,
    tagValuesRegex: TAG_VALUE_REGEX,
    tagKeysRegex: TAG_KEY_REGEX,
  });
}

export function areValidClusterProfileTags() {
  return areValidKubernetesTags({
    onlySpectroTags: true,
    tagValuesRegex: TAG_VALUE_REGEX,
    tagKeysRegex: TAG_VALUE_REGEX,
    genericMessage: () => (
      <InvalidFieldMessage
        text={language.t(
          "One or more tags are invalid. Please refer to the guidelines in the tooltip"
        )}
      />
    ),
  });
}

export function areValidEdgeHostIds({
  errorMessageText,
  genericMessage = ({ text, tooltipPlacement, rules } = {}) => (
    <InvalidFieldMessage
      text={text}
      tooltipPlacement={tooltipPlacement}
      rules={rules}
    />
  ),
  tooltipPlacement = "bottomRight",
  rules = KUBERNETES_NAME_RULES,
  nameRegex = KUBERNETES_NAME_REGEX,
  nameMaxSize = 63,
} = {}) {
  return (hostIds) => {
    if (!hostIds) {
      return false;
    }
    const invalidIds = hostIds.filter(
      (id) => !id.match(nameRegex) || id.length > nameMaxSize
    );
    return invalidIds.length === 0
      ? false
      : {
          result: genericMessage({
            text: errorMessageText,
            tooltipPlacement,
            rules,
          }),
          invalidTags: invalidIds,
        };
  };
}

export function isValidName({
  errorMessageText = "Name must respect kubernetes object naming conventions",
  tooltipPlacement,
  genericMessage = ({ text, tooltipPlacement, rules } = {}) => (
    <InvalidFieldMessage
      text={text}
      tooltipPlacement={tooltipPlacement}
      rules={rules}
    />
  ),
  rules = NAME_RULES,
  nameRegex = NAME_REGEX,
  nameMaxSize = 32,
} = {}) {
  return (value) => {
    const invalidName = !value.match(nameRegex) || value.length > nameMaxSize;
    if (!invalidName) return false;
    return {
      result: genericMessage({
        text: errorMessageText,
        tooltipPlacement,
        rules,
      }),
      invalidName,
    };
  };
}

export function isKubernetesName({
  allowDashStartEnd = false,
  dashRuleMessage = () => language.t("Field can't start or end with a dash"),
  genericMessage = () =>
    language.t(
      "Field must contain only lowercase letters, dashes and numbers. It also must start with a letter."
    ),
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    if (
      !allowDashStartEnd &&
      [value[0], value[value.length - 1]].includes("-")
    ) {
      return dashRuleMessage();
    }
    return value.match(/^[a-z]([-a-z0-9]*[a-z0-9])?$/)
      ? false
      : genericMessage();
  };
}

export function MaxLength(
  maxNumber,
  {
    message = () =>
      language.t("Only a maximum of {{maxNumber}} characters are allowed", {
        maxNumber,
      }),
  } = {}
) {
  return (value) => (value && value.length > maxNumber ? message() : false);
}

export function MinLength(
  minNumber,
  {
    message = () =>
      language.t("Field must contain at least {{minNumber}} characters", {
        minNumber,
      }),
  } = {}
) {
  return (value) => (value && value.length < minNumber ? message() : false);
}

export function isValidUrl(
  options,
  { message = () => language.t("Field must contain a valid url") } = {}
) {
  const defaultOptions = {
    protocols: ["http", "https"],
    require_protocol: true,
    require_tld: true,
    require_valid_protocol: true,
  };
  const urlOptions = { ...defaultOptions, ...options };

  return (value) => (value && !isUrl(value, urlOptions) ? message() : false);
}

export function isValidPortRange({
  allowSinglePorts = true,
  message = () =>
    allowSinglePorts
      ? language.t("Invalid port or port range")
      : language.t("Field must contain a port range"),
} = {}) {
  return (value) => {
    if (isPortRange(value)) {
      return false;
    }

    if (allowSinglePorts && isPort(value)) {
      return false;
    }

    return message();
  };
}

export function areValidIPRangesTags({
  genericMessage = () => language.t("One or more CIDRs are invalid"),
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    const invalidTags = value.filter((cidr) => !isIPRange(cidr));

    return invalidTags.length === 0
      ? false
      : {
          result: genericMessage(),
          invalidTags,
        };
  };
}

export function isValidIPRange({
  version = "4",
  message = () => language.t("Invalid IP Range"),
} = {}) {
  return (value) => (value && !isIPRange(value, version) ? message() : false);
}

export function isValidIP({
  version = "4",
  message = () => language.t("Invalid IP"),
} = {}) {
  return (value) => {
    if (Array.isArray(value)) {
      return value.some((ip) => !isIP(ip, version)) ? message() : false;
    }
    return value && !isIP(value, version) ? message() : false;
  };
}

export function isValidDomain({
  message = () => language.t("Invalid domain"),
  require_tld = true,
} = {}) {
  return (value) => {
    if (Array.isArray(value)) {
      return value.some((domain) => !isFQDN(domain, { require_tld }))
        ? message()
        : false;
    }
    return value && !isFQDN(value, { require_tld }) ? message() : false;
  };
}

export function validateDomainOrIP({
  message = () => language.t("Must be IP or domain"),
} = {}) {
  return function validation(value) {
    const isInvalid = [isValidIP(), isValidDomain()].every((fn) => !!fn(value));

    return isInvalid ? message() : false;
  };
}

export function isValidPrefix({
  message = () => language.t("Field must contain a value between 1 and 32"),
} = {}) {
  return (value) =>
    isNaN(value) || value < 1 || value > 32 ? message() : false;
}

export function isValidGateway({
  message = () => language.t("Invalid gateway"),
} = {}) {
  return (value) =>
    value && !isIPRange(value) && !isIP(value) ? message() : false;
}

export function isValidTagSelection({
  reservedTags = [],
  message = () =>
    language.t(
      "Invalid tag name. Following tag name prefixes are reserved: '{{tags}}'",
      {
        tags: reservedTags.join(","),
      }
    ),
} = {}) {
  const isReservedTagIncluded = (tag) =>
    reservedTags.some((reservedTag) =>
      tag.toLowerCase().startsWith(reservedTag.toLowerCase())
    );
  return (tags) =>
    tags?.some((tag) => isReservedTagIncluded(tag)) ? message() : false;
}

export function EmailType({
  message = () => language.t("Field must contain a valid email"),
} = {}) {
  return (value) => (value && !isEmail(value) ? message() : false);
}

export function isValidPort({
  message = () => language.t("Field must be a valid port number"),
} = {}) {
  return (value) => (value && !isPort(value) ? message() : false);
}

export function isValidReplicas({
  message = () => language.t("Replicas must be an integer between 1 and 10"),
} = {}) {
  return (value) =>
    typeof value === "undefined" ||
    value === null ||
    value < 1 ||
    value > 10 ||
    !Number.isInteger(value)
      ? message()
      : false;
}

export function isValidAbsolutePath({
  message = () => language.t("Field must be a valid absolute path"),
} = {}) {
  return (value) =>
    /^\/([A-z0-9-_+]+\/)*([A-z0-9]+)/.test(value) ? false : message();
}

export function EmptyString({
  message = () => language.t("Invalid field"),
} = {}) {
  return (value) => (value && !value.trim() ? message() : false);
}

export function DebouncedRule({ delay = 400 } = {}) {
  return (fn) => debouncePromise(fn, delay);
}

export function isJSONFormat({
  message = () => language.t("Field must contain a valid JSON object"),
} = {}) {
  return (value) => (value && !isJSON(value.trim()) ? message() : false);
}

export function isWorkspaceQuotaExceeded() {
  return (value, key, data) => {
    const summedNsCPUCores = data.namespaces.reduce(
      (acc, ns) =>
        ns.children.reduce(
          (clustersAcc, cluster) =>
            parseFloat(cluster?.alocCpu || 0) + clustersAcc,
          0
        ) + acc,
      0
    );

    const summedNsMemory = data.namespaces.reduce(
      (acc, ns) =>
        ns.children.reduce(
          (clustersAcc, cluster) =>
            parseFloat(cluster?.alocMemory || 0) + clustersAcc,
          0
        ) + acc,
      0
    );

    const parsedCpuQuota = parseFloat(data?.cpuQuota || 0);
    if (parsedCpuQuota > 0 && summedNsCPUCores > parsedCpuQuota) {
      return language.t(
        "CPU resources exceed workspace quota. Total {{cores}} cores out of maximum {{quota}} cores",
        {
          cores: summedNsCPUCores,
          quota: data.cpuQuota,
        }
      );
    }

    const parsedMemoryQuota = parseFloat(data?.memoryQuota || 0);
    if (parsedMemoryQuota && summedNsMemory > parsedMemoryQuota) {
      return language.t(
        "Memory resources exceed workspace quota. Total {{memory}}GB out of maximum {{quota}}GB",
        {
          memory: summedNsMemory,
          quota: data.memoryQuota,
        }
      );
    }
  };
}

const emptyAllocationValues = ["", undefined];

function checkAllocationValues(data, type) {
  const { alocCpu, alocMemory } = data;
  const alocValues = [alocCpu, alocMemory];
  const areAlocEqual = alocCpu === alocMemory;

  const isValid = alocValues.every((value) => {
    const isEmpty = emptyAllocationValues.includes(value) && areAlocEqual;
    let isZero = parseFloat(value) === 0 && areAlocEqual;
    let isPositive = parseFloat(value) > 0;
    let isUnlimited = false;

    if (type === "clusters") {
      isUnlimited = parseFloat(value) === -1 && areAlocEqual;
    }

    return isZero || isUnlimited || isEmpty || isPositive;
  });

  return !isValid;
}

export function isValidNamespaceAllocation({
  message = () =>
    language.t(
      "Either both namespace cpu and memory should be 0 or must be greater than 0"
    ),
} = {}) {
  return (value, key, data) => {
    const errors = (data?.namespaces || []).find((ns) => {
      if (checkAllocationValues(ns, "namespaces")) {
        return ns;
      }
      return false;
    });

    if (!!errors) {
      return message();
    }
  };
}

export function isValidClusterAllocation({
  message = () =>
    language.t(
      "Either both cluster cpu and memory should be 0/-1 or must be greater than 0"
    ),
} = {}) {
  return (value, key, data) => {
    const errors = (data?.namespaces || []).find((ns) => {
      if (
        (ns?.children || []).some((cluster) =>
          checkAllocationValues(cluster, "clusters")
        )
      ) {
        return ns;
      }
      return false;
    });

    if (!!errors) {
      return message();
    }
  };
}

export function* isUnlimitedAloc(value, key, data) {
  for (const index in data.namespaces) {
    for (const childIndex in data.namespaces[index].children) {
      const hasQuota =
        parseFloat(data.cpuQuota) > 0 || parseFloat(data.memoryQuota) > 0;
      const isAlocCpuNegative =
        data.namespaces[index]?.children[childIndex]?.alocCpu < 0;

      const isAlocMemoryNegative =
        data.namespaces[index]?.children[childIndex]?.alocMemory < 0;

      const message = language.t(
        "Cluster can't have unlimited allocation due to workspace {{aloc}} quota",
        {
          aloc: parseFloat(data.cpuQuota) > 0 ? "CPU" : "Memory",
        }
      );

      yield {
        field: `namespaces.${index}.children.${childIndex}.alocCpu`,
        result: hasQuota && isAlocCpuNegative ? message : false,
      };
      yield {
        field: `namespaces.${index}.children.${childIndex}.alocMemory`,
        result: hasQuota && isAlocMemoryNegative ? message : false,
      };
    }
  }
}

export function SemanticVersion({
  message = () =>
    language.t(
      "Must be a valid semantic version format. Example: 1.0.0, 1.0.0-alpha.1, 1.0.0-beta.2"
    ),
} = {}) {
  return (value) => {
    return value && isSemver(value) === null ? message() : false;
  };
}

export function isValidRegexNamespace({
  message = () => language.t("Invalid regex expression"),
} = {}) {
  return (value, key, data) => {
    if (["/", "~/"].some((start) => value.startsWith(start))) {
      if (!isValidRegex(value)) {
        return message();
      }
      return false;
    }

    return isKubernetesName()(value, key, data);
  };
}

export function isBelowLimit(field, lowerLimit) {
  return (value) => {
    if (value < lowerLimit) {
      return language.t(`{{field}} must be at least {{lowerLimit}}`, {
        field,
        lowerLimit,
      });
    }

    return false;
  };
}

export function isValidYAMLFormat({
  message = () => language.t("Invalid YAML format"),
} = {}) {
  return (value, _1, _2) => {
    if (typeof value === "undefined") {
      return false;
    }

    const yamlFiles = `${value}\n`
      .replace(/\n---([\s]+)?\n/g, "\n---\n")
      .split("\n---\n");

    for (const file of yamlFiles) {
      try {
        YAML.parse(file, { strict: false });
      } catch (err) {
        return message();
      }
    }

    return false;
  };
}

export function isValidVariable({
  hidden = false,
  immutable = false,
  required = false,
  format = "string",
  regex = null,
  isSensitive = false,
} = {}) {
  return ({ value, skipValidation = false }) => {
    if (
      hidden ||
      immutable ||
      skipValidation ||
      format === "boolean" ||
      (value === MASKED_VARIABLE_DEFAULT_VALUE && isSensitive)
    ) {
      return false;
    }

    const rules = [];

    if (required) {
      rules.push(Missing());
    }

    if (!!regex && value) {
      const regexCheck = (value) =>
        !new RegExp(regex).test(value)
          ? language.t(`Value does not match the regex pattern "${regex}"`)
          : false;
      rules.push(regexCheck);
    }

    if (format === "number") {
      const numberCheck = (value) =>
        isNaN(value) ? language.t("Value is not a number") : false;
      rules.push(numberCheck);
    }

    if (format === "version") {
      rules.push(SemanticVersion());
    }

    if (format === "ipv4cidr") {
      rules.push(
        isValidIPRange({
          version: format.replace("ipv", "").replace("cidr", ""),
        })
      );
    }

    if (["ipv4", "ipv6"].includes(format)) {
      rules.push(
        isValidIP({
          version: format.replace("ipv", ""),
        })
      );
    }

    const results = rules.map((rule) => rule(value)).filter((res) => !!res);

    if (results.length > 0) {
      return results[0];
    }

    return false;
  };
}

export function isValidTaintKey({
  message = () =>
    language.t(
      "Key can contain only alphanumeric characters, dots, hyphens or underscores and must begin with an alphanumeric character"
    ),
} = {}) {
  return (value) =>
    /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\/)?[a-zA-Z0-9][a-zA-Z0-9._-]*$/.test(
      value
    )
      ? false
      : message();
}

export function isValidTaintValue({
  message = () =>
    language.t(
      "Value can contain only alphanumeric characters, dots, hyphens or underscores and must begin with an alphanumeric character"
    ),
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    return /^[a-zA-Z0-9][a-zA-Z0-9._-]*$/.test(value) ? false : message();
  };
}
