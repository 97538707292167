import React, { useCallback } from "react";
import DocLink from "components/common/DocLink";
import styled, { css } from "styled-components";
import Icon from "components/ui/Icon";
import { faCircleQuestion } from "@fortawesome/pro-light-svg-icons";
import Button, { TextButton } from "components/ui/Button";
import history from "services/history";
import { CLUSTERS } from "utils/constants/routes";
import { generatePath } from "react-router";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import notifications from "services/notifications";
import { getFreemiumUsage } from "state/auth/selectors";
import illustration_empty from "assets/images/illustration_empty.png";

import {
  borderColorDefault,
  iconInformation,
  midGray,
  nearBlack,
  textInformation,
  white,
  adminBlue,
  darkGray,
} from "utils/constants/colors";
import { useTheme } from "components/common/Theme";

const StyledIllustration = styled.div`
  display: flex;
  flex-direction: column;
  width: 488px;
  height: 488px;
  background-repeat: no-repeat;
  background-size: contain;

  ${(props) =>
    props.illustration &&
    css`
      background-image: url(${props.illustration});
    `}
`;
const StartPageWrapper = styled.div`
  background-color: ${white};
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  box-sizing: content-box;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 488px;
  justify-content: center;
  button {
    margin: 20px 15px 0 0;
  }
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: ${nearBlack};
  padding-bottom: 24px;
`;

const Subtitle = styled.div`
  color: ${midGray};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 20px;
`;

const TopIllustrationSection = styled.div`
  display: flex;
`;
const LearnMoreSection = styled.div`
  display: flex;
  height: 160px;
  border: 1px solid ${borderColorDefault};
  width: 1000px;
  border-radius: 5px;
  flex-direction: column;
  padding: 15px;
`;
const LinkSection = styled.div`
  display: flex;

  div {
    flex: 1;
  }
`;

const SubLinksSection = styled.div`
  display: flex;
  flex-direction: column;
`;
const LearnMoreTitleSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 17px;

  .anticon {
    color: ${iconInformation};
    margin-right: 12px;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  span {
    font-weight: 600;
    color: ${textInformation};
  }
`;
const UpgradeButton = styled(TextButton)`
  flex-grow: 1;
  background-color: #6ddcb4;
  margin: 8px 0 8px 0;
  display: inline-block;
  line-height: 32px;
  color: ${adminBlue};
  &:focus,
  &:hover {
    color: ${adminBlue};
    background-color: #6ddcb4;
  }
`;
const ActiveFiltersWrapper = styled.div`
  font-size: 40px;
  padding-left: 35px;
  font-weight: 600;
  line-height: 60px;
  background-image: url(${illustration_empty});
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  color: ${darkGray};
`;

export default function StartPage({ isLoading, items, hasActiveFilters }) {
  const { t } = useTranslation();
  const { startPageIllustration } = useTheme();

  const hasFreemiumOverUsage =
    useSelector(getFreemiumUsage)?.usagePercent >= 100;

  const onClickNewCluster = useCallback(() => {
    if (!hasFreemiumOverUsage) {
      return;
    }

    return notifications.warning({
      key: "reachedLimit",
      duration: 0,
      message: t(
        "You have reached the plan limit. Your plan usage will be renewed at the beginning of the next month"
      ),
      description: (
        <UpgradeButton
          onClick={() => history.push("/admin/settings/plandetails")}
        >
          {t("Go on-demand")}
        </UpgradeButton>
      ),
    });
  }, [hasFreemiumOverUsage, t]);
  if (isLoading || items.length) {
    return null;
  }
  return (
    <>
      {hasActiveFilters ? (
        <ActiveFiltersWrapper>
          {t("No clusters match the filter criteria.")}
        </ActiveFiltersWrapper>
      ) : (
        <StartPageWrapper>
          <TopIllustrationSection>
            <ContentWrapper>
              <Title>{t("Step into Cluster Configuration")}</Title>
              <Subtitle>
                {t("Dive into hassle-free Kubernetes management.")}
              </Subtitle>
              <Subtitle>
                {t(
                  "Choose your cluster type - public cloud, data center, edge, or import your own."
                )}
              </Subtitle>
              <DocLink
                text={t("More about Clusters in Palette")}
                location="clusters"
              />
              <div>
                <Button
                  size="large"
                  data-qa="open-new-cluster-panel"
                  onClick={() => {
                    hasFreemiumOverUsage
                      ? onClickNewCluster()
                      : history.push(
                          generatePath(CLUSTERS.SELECT_CLOUD, {
                            tab: "cloud",
                          })
                        );
                  }}
                >
                  {t("Create Cluster")}
                </Button>
                <Button
                  secondary
                  size="large"
                  data-qa="import-cluster-panel"
                  onClick={() => {
                    hasFreemiumOverUsage
                      ? onClickNewCluster()
                      : history.push(CLUSTERS.IMPORT_CLUSTER);
                  }}
                >
                  {t("Import Cluster")}
                </Button>
              </div>
            </ContentWrapper>
            <StyledIllustration illustration={startPageIllustration} />
          </TopIllustrationSection>
          <LearnMoreSection>
            <LearnMoreTitleSection>
              <Icon awesome={faCircleQuestion} />
              <span>{t("Learn More")}</span>
            </LearnMoreTitleSection>
            <LinkSection>
              <SubLinksSection>
                <DocLink
                  text={t("Public Cloud Clusters")}
                  location="public-cloud"
                />
                <DocLink
                  text={t("Data Center Clusters")}
                  location="data-center"
                />
                <DocLink text={t("Edge Clusters")} location="edge" />
                <DocLink
                  text={t("Managing Clusters")}
                  location="cluster-management"
                />
              </SubLinksSection>
              <SubLinksSection>
                <DocLink
                  text={t("How to Deploy a Cluster in Palette?")}
                  location="deploy-cluster"
                />
                <DocLink
                  text={t("How to use Cluster Profiles in Palette?")}
                  location="cluster-profiles"
                />
                <DocLink
                  text={t(
                    "How to use Terraform to deploy and manage K8s clusters?"
                  )}
                  location="terraform"
                />
                <DocLink
                  text={t("How to importing existing K8s clusters in Palette?")}
                  location="imported-clusters"
                />
              </SubLinksSection>
            </LinkSection>
          </LearnMoreSection>
        </StartPageWrapper>
      )}
    </>
  );
}
